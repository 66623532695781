.lendb-home-page{
  .main-w{
    margin: 0 auto;
    max-width: 11.9rem;
    padding: 0 .3rem;
    box-sizing: border-box;
  }
  .logo{
    width: 1.22rem;
    height: .36rem;
    background: url('../../assets/images/logo.png') no-repeat center;
    background-size: cover;
    z-index: 2;
    position: relative;
  }
  .banner-box{
    width: 100vw;
    height: 7.96rem;
    background: url('../../assets/images/banner.png') no-repeat center;
    background-size: cover;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    .arrow-bottom{
      width: .38rem;
      height: .43rem;
      position: absolute;
      bottom: 1.36rem;
      left: 50%;
      transform: translate(-50%);
      z-index: 2;
      background: url('../../assets/images/arrow.png') no-repeat center;
      background-size: contain;
      animation: rightan 2s infinite;
    }
    @keyframes rightan {
      from{
        bottom: 1.36rem;
        opacity: 0;
      }
      to{
        bottom: 1.06rem;
        opacity: 1;
      }
    }
    .logo-box{
      position: relative;
      width: 1.72rem;
      height: .68rem; 
      border-radius: .34rem;
      margin-top: .55rem;
      .logo-wapper{
        width: 1.72rem;
        height: .68rem; 
        border-radius: .34rem;
        background: #0A0A0A;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
      }
      .to-app{
        position: absolute;
        user-select: none;
        width: 2.48rem;
        height: .68rem;
        border-radius: .34rem;
        line-height: .68rem;
        text-align: center;
        border: .01rem solid #000;
        box-sizing: border-box;
        top: 0;
        left: 1.05rem;
        box-sizing: border-box;
        z-index: 1;
        padding-left: .4rem;
        svg{
          margin-left: .06rem;
        }
        &:hover{
          color: #FFF500;
          svg path{
            stroke: #FFF500;
          }
        }
      }
    }
    .content-box{
      max-width: 7.2rem;
      margin-top: .74rem;
      .title{
        font-size: .5rem;
      }
      .desc{
        font-size: 0.2rem;
      }
      svg{
        margin-top: 0.55rem;
        cursor: pointer;
        &:hover path{
          stroke: #FFF500;
        }
      }
      .desc-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: .4rem;
        gap: .6rem;
        .item-title{
          font-size: .24rem;
        }
      }
    }
  }
  .main-box{
    width: 100%;
    height: max-content;
    background-color: #0A0A0A;
    border-top-left-radius: .9rem;
    border-top-right-radius: .9rem;
    transform: translate(0, -.76rem);
    padding: 1.55rem 0 1.21rem;
    box-sizing: border-box;
    text-align: center;
    .join-box{
      max-width: 6.2rem;
      margin: 0 auto;
      .join-title{
        font-size: .4rem;
      }
      .media-box{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: .5rem;
        .icon{
          width: 1.98rem;
          height: 1.98rem;
          border-radius: 50%;
          margin: 0 auto;
          user-select: none;
          border: .01rem solid #252525;
          background-color: #0A0A0A;
          position: relative;
          transition: all 0.2s ease;
          .hover-arrow{
            position: absolute;
            bottom: 0;
            right: 0;
            opacity: 0;
            z-index: 2;
          }
          &:hover{
            background-color: #F5A601;
            .media-icon path{
              fill: #0A0A0A;
            }
            .hover-arrow{
              opacity: 1;
            }
          }
        }
        .line{
          width: .01rem;
          height: 1.1rem;
          background-color: #252525;
          margin: .44rem .3rem 0;
        }
        .desc{
          margin-top: .16rem;
        }
      }
    }
    .btc-box{
      margin-top: .9rem;
      width: 100%;
      height: 3.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      &::after{
        content: '';
        width: 100%;
        height: 3.2rem;
        position: absolute;
        display: block;
        bottom: -.2rem;
        left: 0;
        background: url('../../assets/images/join-bg.png') no-repeat center;
        background-size: 100% 100%;
      }
    }
    .btc-svg, .btc-desc, .btc-title{
      z-index: 2;
      position: relative;
    }
    .btc-svg{
      text-align: center;
    }
    .btc-title{
      font-size: .4rem;
      margin-top: .16rem;
      background: var(--Linear, linear-gradient(90deg, #FB3A03 2.82%, #F58924 36.35%, #FFBA05 72.3%, #FFE8C9 100%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .btc-desc{
      max-width: 7.25rem;
      margin: .2rem auto 0;
    }
  }
  .footer-box{
    width: 100%;
    background: #121212;
    overflow: hidden;
    padding-bottom: 1.26rem;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      display: block;
      width: 10rem;
      height: 2.99rem;
      background: #FF3D00;
      filter: blur(.75rem);
      left: 50%;
      bottom: -3.5rem;
      border-radius: 12.3rem;
      transform: translate(-50%, 0);
    }
    .main-w{
      margin: .45rem auto 0;
      max-width: 11.9rem;
      padding: 0 .2rem;
      display: flex;
      justify-content: space-between;
      position: relative;
    }
    .img-box{
      display: flex;
      align-items: center;
      .media {
        gap: .34rem;
        margin-left: .8rem;
        svg{
          cursor: pointer;
          user-select: none;
          &:hover path{
            fill: #F5A601;
          }
        }
      }
    }
    .doc-link{
      margin-top: .1rem;
      a{
        font-size: .16rem;
        font-family: DINPro-Medium;
        &:hover{
          color: #F5A601;
        }
      }
      display: flex;
      gap: .3rem;
      justify-content: flex-end;
    }
  }
}

@media screen and (min-width: 2880px) {
  .lendb-home-page{
    .logo-box{
      width: 344px !important;
      height: 136px !important;
      border-radius: 68px !important;
      margin-top: 110px !important;
      .logo-wapper{
        width: 344px !important;
        height: 136px !important; 
        border-radius: 68px !important;
      }
      .logo{
        width: 244px !important;
        height: 72px !important;
      }
      .to-app{
        font-size: 36px !important;
        width: 496px !important;
        height: 136px !important;
        border-radius: 68px !important;
        line-height: 136px !important;
        border: 2px solid #000 !important;
        top: 0;
        left: 210px !important;
        padding-left: 80px !important;
        svg{
          width: 22px;
          height: 22px;
          font-size: 22px !important;
          margin-left: 12px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .lendb-home-page{
    .main-w{
      margin: 0 auto;
      max-width: 1190px;
      padding: 0 20px;
    }
    .logo{
      width: 122px;
      height: 36px;
    }
    .banner-box{
      width: 100vw;
      min-height: 800px;
      background: url('../../assets/images/banner-m.png') no-repeat center;
      background-size: cover;
      .arrow-bottom{
        bottom: 86px;
        left: 50%;
        transform: translate(-50%);
        z-index: 2;
        background: url('../../assets/images/arrow.png') no-repeat center;
        background-size: contain;
        animation: rightan 2s infinite;
      }
      @keyframes rightan {
        from{
          bottom: 86px;
          opacity: 0;
        }
        to{
          bottom: 56px;
          opacity: 1;
        }
      }
      .logo-box{
        margin-top: 30px;
        width: 111px;
        height: 44px;
        border-radius: 34px;
        margin-top: 35px;
        .logo{
          width: 78px;
          height: 22px;
        }
        .logo-wapper{
          width: 111px;
          height: 44px; 
          border-radius: 34px;
        }
        .to-app{
          width: 155px;
          height: 44px;
          border-radius: 34px;
          line-height: 44px;
          top: 0;
          left: 67px;
          padding-left: 55px;
          font-size: 12px;
          display: flex;
          align-items: center;
          svg{
            width: 10px;
            height: 3px;
            font-size: 10px;
            margin-left: 6px;
          }
        }
      }
      .content-box{
        margin-top: 40px;
        .title{
          font-size: 40px;
          line-height: 45px;
        }
        .desc{
          font-size: 14px;
          margin-top: 20px;
        }
        .desc-box{
          margin-top: 25px;
          gap: .3rem;
          .item-title{
            font-size: 20px;
          }
          .item-desc{
            font-size: 12px;
          }
        }
      }
    }
    .main-box{
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      transform: translate(0, -30px);
      padding: 90px 0 100px;
      .join-box{
        max-width: 620px;
        .join-title{
          font-size: 30px;
        }
        .join-desc{
          font-size: 12px;
        }
        .media-box{
          margin-top: 34px;
          .icon{
            width: 86px;
            height: 86px;
            .media-icon{
              width: 20px !important;
              font-size: 20px !important;
            }
            .tele{
              width: 23px !important;
              font-size: 23px !important;
            }
            .hover-arrow{
              display: none;
            }
          }
          .line{
            width: 1px;
            height: 42.5px;
            margin: 22px 20px 0;
          }
          .desc{
            margin-top: 8px;
            font-size: 12px;
          }
        }
      }

      .btc-box{
        margin-top: 97px;
        height: max-content;
        position: relative;
        &::after{
          content: '';
          width: 100%;
          height: 150px;
          position: absolute;
          display: block;
          bottom: -32px;
          left: 0;
          background: url('../../assets/images/join-bg.png') no-repeat center;
          background-size: 100% 100%;
        }
      }
      .btc-title{
        font-size: 30px;
        margin-top: 8px;
      }
      .btc-desc{
        font-size: 12px;
      }
      .btc-svg, .btc-desc, .btc-title{
        z-index: 2;
        position: relative;
      }
    }
    .footer-box{
      padding-bottom: 50px;
      &::after{
        width: 80vw;
        height: 299px;
        background: #FF3D00;
        filter: blur(75px);
        left: 50%;
        bottom: -350px;
        border-radius: 80vw;
        transform: translate(-50%, 0);
      }
      .main-w{
        max-width: 1190px;
        padding: 0 30px;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        flex-direction: column;
      }
      .img-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .media {
          gap: 25px;
          margin-top: 0;
          margin-left: 0;
        }
      }
      .doc-link{
        margin-top: 20px;
        display: flex;
        gap: 15px;
        justify-content: flex-start;
        flex-direction: column;
      }
    }
  }
}