@font-face {
  font-family: DINPro-Book;
  src: url('./font/DINPro-Book.otf');
}
@font-face {
  font-family: DINPro-Medium;
  src: url('./font/DINPro-Medium.otf');
}
@font-face {
  font-family: DINPro-Bold;
  src: url('./font/DINPro-Bold.otf');
}

.ff-400{
  font-family: DINPro-Book;
}
.ff-500{
  font-family: DINPro-Medium;
}
.ff-700{
  font-family: DINPro-Bold;
}