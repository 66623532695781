@import "./common";
@import "./font";

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dd,
ul,
ol,
form {
  margin: 0;
} 

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  color: #fff;
}

ul,
li,
ol {
  padding-left: 0;
  list-style: none;
  list-style-position: outside;
}

a {
  color: #fff;
  text-decoration: none;
}

html,
body {
  height: 100%;
  color: #fff;
  background: #0A0A0A;
  font-family: DINPro-Book;
  line-height: 1;
  font-weight: 400;
  font-feature-settings: normal;
  min-height: 100vh;
}
p{
  line-height: 1.5;
}
p, div{
  font-size: .18rem;
}

.no-scroll{
  height: 100%;
  overflow: hidden;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button{
  -webkit-appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar{
  display:none;
}